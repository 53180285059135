import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const Slider = ({ firstSlide, isList, children, ...props }) => (
  <Swiper threshold={5} wrapperTag={isList ? 'ul' : 'div'} {...props}>
    {firstSlide && (
      <SwiperSlide tag={isList ? 'li' : 'div'}>{firstSlide}</SwiperSlide>
    )}
    {children.map((child, id) => (
      <SwiperSlide key={id} tag={isList ? 'li' : 'div'}>
        {child}
      </SwiperSlide>
    ))}
  </Swiper>
);
