import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, HtmlContent } from './ui';

const StyledSitewideNotice = styled.div`
  padding: 10px 0;
  text-align: center;
  background-color: ${brandColours.quinary};

  p {
    margin: 0;
    font-weight: ${fontWeights.regular};
    ${fontSize(14)};
    line-height: 1.5;

    ${minBreakpointQuery.large`
      ${fontSize(15)};
    `}
  }

  a {
    color: ${standardColours.darkGrey};
  }
`;

const SitewideNotice = () => {
  const {
    datoCmsHeader: { sitewideNotice },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      datoCmsHeader {
        sitewideNotice
      }
    }
  `);

  return (
    sitewideNotice && (
      <StyledSitewideNotice>
        <Container>
          <HtmlContent html={sitewideNotice} />
        </Container>
      </StyledSitewideNotice>
    )
  );
};

export default SitewideNotice;
