import { css } from 'styled-components';
import { breakpointSizes } from '../../styles';

export const sectionMargins = (minMargin = '40px', maxMargin = '100px') => {
  const calculatedMargin = `
    calc(
      ${minMargin} + (${parseInt(maxMargin)} - ${parseInt(minMargin)}) *
        (
          (100vw - ${breakpointSizes.tiny}px) /
            (${breakpointSizes.xxxxlarge} - ${breakpointSizes.tiny})
        )
    )
  `;

  return css`
    margin-top: ${minMargin};
    margin-bottom: ${minMargin};

    @media screen and (min-width: ${breakpointSizes.tiny}px) {
      margin-top: ${calculatedMargin};
      margin-bottom: ${calculatedMargin};
    }

    @media screen and (min-width: ${breakpointSizes.xxxxlarge}px) {
      margin-top: ${maxMargin};
      margin-bottom: ${maxMargin};
    }
  `;
};
