import React from 'react';
import styled from 'styled-components';
import { brandColours } from '../../styles';
import { Svg } from './Svg';
import wave from '../../images/wave.svg';

const StyledWave = styled(Svg)`
  height: 6px;
  width: 80px;
  stroke: ${brandColours.primary};
`;

export const Wave = ({ ...props }) => <StyledWave image={wave} {...props} />;
