import React, { useContext, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { standardColours, zIndexLayers } from '../styles';
import { Container } from './ui';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';
import { StoreContext } from '../context/StoreContext';

const StyledSearch = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndexLayers.fourth};
  display: flex;
  align-items: center;
  background-color: ${standardColours.white};
`;

const Search = () => {
  const { isSearchOpen } = useContext(StoreContext);
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);

  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const searchClient = {
    search(requests) {
      if (requests[0].params.query === '') {
        return [];
      }

      return algoliaClient.search(requests);
    },
  };

  const indices = [{ name: `Products`, title: `Products` }];

  return (
    isSearchOpen && (
      <StyledSearch>
        <Container>
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
            <SearchResult indices={indices} show={query && query.length > 0} />
          </InstantSearch>
        </Container>
      </StyledSearch>
    )
  );
};

export default Search;
