import { css } from 'styled-components';
import {
  breakpointSizes,
  brandFonts,
  fontWeights,
  fluidFontSize,
} from '../../styles';

export const headingStyles = () => {
  return css`
    font-family: ${brandFonts.secondary};
    font-weight: ${fontWeights.regular};
    ${fluidFontSize(
      '28px',
      '46px',
      breakpointSizes.tiny,
      breakpointSizes.large
    )};
    line-height: 1.2;
  `;
};
