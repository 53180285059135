export const standardColours = {
  black: '#000',
  transparentBlack: value => {
    return `rgba(0, 0, 0, ${value})`;
  },
  darkGrey: '#1D1D1D',
  grey: '#373737',
  lightGrey: '#E1E1E1',
  lighterGrey: '#F4F4F4',
  white: '#FFF',
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#058267', // green
  secondary: '#E5B1B6', // pink
  tertiary: '#02392D', // dark green
  quaternary: '#F48640', // orange
  quinary: '#DCFE02', // neon green
};
