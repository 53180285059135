import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  commonFormElementStyles,
  visuallyHidden,
} from '../styles';

const StyledProductQuantity = styled.label`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.span`
  ${({ isCartItem }) => {
    if (isCartItem) {
      return css`
        ${visuallyHidden()};
      `;
    } else {
      return css`
        margin-right: 14px;
        font-family: ${brandFonts.secondary};
        font-weight: ${fontWeights.bold};
      `;
    }
  }}
`;

const StyledText = styled.span`
  ${visuallyHidden()};
`;

const StyledQuantityWrap = styled.div`
  position: relative;
`;

const StyledQuantityButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ alt }) => (alt ? 'right: 0' : 'left: 0')};
  width: 35px;
  background: none;
  border: none;

  ${minBreakpointQuery.tiny`
    width: 40px;
  `}

  &:before {
    content: ${({ alt }) => (alt ? '"+"' : '"-"')};
    color: ${brandColours.grey};
    font-weight: ${fontWeights.medium};
    ${fontSize(24)};
    line-height: 0.8;
  }
`;

const StyledQuantity = styled.input`
  ${commonFormElementStyles()};
  padding-right: 35px;
  padding-left: 35px;
  height: 40px;
  width: 100px;
  text-align: center;
  border-radius: 0;
  -moz-appearance: textfield;

  ${minBreakpointQuery.tiny`
    padding-right: 40px;
    padding-left: 40px;
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ProductQuantity = ({
  quantity,
  changeFunction,
  isCartItem,
  ...props
}) => {
  return (
    <StyledProductQuantity htmlFor="product-form-quantity" {...props}>
      <StyledLabel isCartItem={isCartItem}>Quantity</StyledLabel>
      <StyledQuantityWrap>
        <StyledQuantityButton
          type="button"
          value="remove"
          onClick={changeFunction}
        >
          <StyledText>Remove One</StyledText>
        </StyledQuantityButton>
        <StyledQuantity
          type="number"
          id="product-form-quantity"
          name="quantity"
          value={quantity}
          min="1"
          required
          onChange={changeFunction}
        />
        <StyledQuantityButton
          type="button"
          value="add"
          alt={true}
          onClick={changeFunction}
        >
          <StyledText>Add One</StyledText>
        </StyledQuantityButton>
      </StyledQuantityWrap>
    </StyledProductQuantity>
  );
};

export default ProductQuantity;
