import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Link, Svg } from './ui';
import logo from '../images/logo.svg';

const StyledLogo = styled(Link)`
  display: inline-block;
`;

const StyledSvg = styled(Svg)`
  height: 40px;
  width: 32px;

  ${minBreakpointQuery.tsmall`
    height: 56px;
    width: 45px;
  `}
`;

const Logo = ({ ...props }) => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
  } = useStaticQuery(graphql`
    query LogoQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
    }
  `);

  return (
    <StyledLogo to="/" {...props}>
      <StyledSvg title={siteName} image={logo} />
    </StyledLogo>
  );
};

export default Logo;
