import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  commonFormElementStyles,
} from '../../styles';

const StyledSelectWrap = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    height: 8px;
    width: 8px;
    border: 2px solid ${brandColours.primary};
    border-top: 0;
    border-left: 0;
    border-radius: 1px;
    pointer-events: none;
    transform: translateY(-75%) rotate(45deg);

    ${minBreakpointQuery.tsmall`
      right: 24px;
      height: 12px;
      width: 12px;
    `}
  }
`;

const StyledSelect = styled.select`
  ${commonFormElementStyles()};
  padding-right: 35px;
  padding-left: 10px;
  width: 100%;
  appearance: none;

  ${minBreakpointQuery.tsmall`
    padding-right: 55px;
    padding-left: 20px;
  `}
`;

export const SelectWrap = ({ children, ...props }) => (
  <StyledSelectWrap {...props}>{children}</StyledSelectWrap>
);

export const Select = ({ children, ...props }) => (
  <StyledSelect {...props}>{children}</StyledSelect>
);
