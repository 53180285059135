import { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../../styles';

export const commonFormElementStyles = () => {
  return css`
    padding: 10px 20px;
    height: 58px;
    width: 100%;
    color: ${standardColours.grey};
    font-family: ${brandFonts.secondary};
    ${fontSize(18)}
    border: 1px solid ${standardColours.lightGrey};

    ::placeholder {
      color: ${standardColours.grey};
    }
  `;
};

export const commonFormButtonStyles = () => {
  return css`
    display: block;
    margin-top: 15px;
    padding: 15px;
    width: 100%;
    color: ${standardColours.white};
    font-weight: ${fontWeights.medium};
    line-height: 1.5;
    background-color: ${brandColours.primary};
    border: none;

    &:disabled {
      opacity: 0.8;
    }
  `;
};
