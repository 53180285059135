import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  visuallyHidden,
  minBreakpointQuery,
  maxBreakpointQuery,
  fontSize,
  zIndexLayers,
} from '../styles';
import NewsletterSignup from './NewsletterSignup';
import { slugify } from '../utils';

const StyledNewsletterSignupPopup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${standardColours.transparentBlack(0.6)};
  z-index: ${zIndexLayers.fourth};
`;

const StyledInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  background-color: ${brandColours.tertiary};
  padding: 30px;

  ${maxBreakpointQuery.smedium`
    margin: 30px;
  `}

  ${minBreakpointQuery.small`
    padding: 40px 60px;
  `}

  ${minBreakpointQuery.large`
    padding: 60px 80px;
  `}
`;

const StyledClose = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 10px;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  color: ${brandColours.primary};
  background-color: ${standardColours.white};
  border-radius: 50%;

  &:before {
    content: '✕';
    ${fontSize(24)};
  }

  span {
    ${visuallyHidden()};
  }
`;

const NewsletterSignupPopup = () => {
  const {
    datoCmsSite: { name },
    datoCmsNewsletterSignup: { isPopupEnabled, heading, text },
  } = useStaticQuery(graphql`
    query PopupQuery {
      datoCmsSite {
        name
      }
      datoCmsNewsletterSignup {
        isPopupEnabled
        heading
        text
      }
    }
  `);

  const localStorageKey = `${slugify(name)}:close-popup-newsletter-signup`;
  const [showPopup, setShowPopup] = useState();
  const [closedPopup, setClosedPopup] = useState();

  useEffect(() => {
    if (isPopupEnabled && showPopup !== false) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 20000);
      return () => clearTimeout(timer);
    }
  }, [showPopup, setShowPopup]);

  useEffect(() => {
    if (isPopupEnabled) {
      setClosedPopup(localStorage.getItem(localStorageKey) || false);
    }
  }, [localStorageKey]);

  const closePopup = () => {
    setClosedPopup(true);
    setShowPopup(false);
    localStorage.setItem(localStorageKey, true);
  };

  return (
    isPopupEnabled &&
    !closedPopup &&
    showPopup && (
      <StyledNewsletterSignupPopup>
        <StyledInner>
          <NewsletterSignup
            heading={heading}
            text={text}
            isPopup={true}
            closePopup={closePopup}
          />
          <StyledClose title="Close" onClick={() => closePopup()}>
            <span>Close</span>
          </StyledClose>
        </StyledInner>
      </StyledNewsletterSignupPopup>
    )
  );
};

export default NewsletterSignupPopup;
