import { css } from 'styled-components';
import { breakpointSizes } from '../../styles';

export const sectionPaddings = (minPadding = '40px', maxPadding = '100px') => {
  const calculatedPadding = `
    calc(
      ${minPadding} + (${parseInt(maxPadding)} - ${parseInt(minPadding)}) *
        (
          (100vw - ${breakpointSizes.tiny}px) /
            (${breakpointSizes.xxxxlarge} - ${breakpointSizes.tiny})
        )
    )
  `;

  return css`
    padding-top: ${minPadding};
    padding-bottom: ${minPadding};

    @media screen and (min-width: ${breakpointSizes.tiny}px) {
      padding-top: ${calculatedPadding};
      padding-bottom: ${calculatedPadding};
    }

    @media screen and (min-width: ${breakpointSizes.xxxxlarge}px) {
      padding-top: ${maxPadding};
      padding-bottom: ${maxPadding};
    }
  `;
};
