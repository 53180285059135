import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Hits, Index } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { minBreakpointQuery, standardColours, fontSize } from '../styles';
import { Link } from './ui';
import { ProductPrice } from './Prices';
import { buildUrl } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledSearchResult = styled.div`
  position: relative;
  display: ${({ show }) => (show ? `block` : `none`)};
`;

const StyledInner = styled.div`
  position: absolute;
  left: 0;
  padding: 10px;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  background-color: ${standardColours.white};
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
`;

const StyledImage = styled(GatsbyImage)`
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;

  ${minBreakpointQuery.small`
    min-width: 60px;
    max-width: 60px;
  `}

  ${minBreakpointQuery.large`
    min-width: 80px;
    max-width: 80px;
  `}
`;

const StyledContent = styled.div`
  margin-left: 20px;

  ${minBreakpointQuery.small`
    margin-left: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 30px;
  `}
`;

const StyledName = styled.p`
  margin-bottom: 4px;
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    margin-bottom: 6px;
    ${fontSize(21)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 8px;
    ${fontSize(24)};
  `}
`;

const PageHit = ({
  hit: {
    title,
    slug,
    image: { gatsbyImageData, altText },
    priceRange,
    variants,
  },
}) => {
  const { toggleSearchOpen } = useContext(StoreContext);
  const urlPath = `shop/${slug}`;

  return (
    <StyledItem
      onClick={() => {
        navigate(buildUrl(urlPath));
      }}
    >
      <StyledImage image={gatsbyImageData} alt={altText || title} />
      <StyledContent>
        <StyledName>
          <Link to={urlPath} onClick={toggleSearchOpen}>
            {title}
          </Link>
        </StyledName>
        <ProductPrice priceRange={priceRange} variants={variants} />
      </StyledContent>
    </StyledItem>
  );
};

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <Hits hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, show }) => (
  <StyledSearchResult show={show}>
    <StyledInner>
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} />
      ))}
    </StyledInner>
  </StyledSearchResult>
);

export default SearchResult;
