import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { VariantPrice } from './Prices';
import ProductQuantity from './ProductQuantity';
import { StoreContext } from '../context/StoreContext';
import { buildUrl } from '../utils';

const StyledCartItem = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 35px;
    margin-bottom: 35px;
  `}
`;

const StyledImage = styled.img`
  margin-right: 20px;
  max-width: 130px;
  object-fit: cover;

  ${minBreakpointQuery.small`
    margin-right: 25px;
  `}
`;

const StyledContent = styled.div`
  padding: 10px 0;
  width: 100%;
`;

const StyledHeading = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
`;

const StyledPrice = styled(VariantPrice)`
  margin: 16px 0;
  ${fontSize(18)};
`;

const StyledQuantity = styled(ProductQuantity)`
  margin: 14px 0;
`;

const StyledRemove = styled.button`
  padding: 0;
  color: ${brandColours.primary};
  font-family: ${brandFonts.secondary};
  ${fontSize(14)};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
`;

const CartItem = ({ data: { id, title, variant, quantity: cartQuantity } }) => {
  const { updateProductInCheckout, removeProductFromCheckout } = useContext(
    StoreContext
  );

  const [quantity, setQuantity] = useState(cartQuantity);

  const {
    image,
    product: { handle },
  } = variant;

  const fullTitle =
    variant.title !== 'Default Title' ? `${title} - (${variant.title})` : title;

  const changeQuantity = async e => {
    const value = e.target.value;
    let changedQuantity;

    if (value === 'remove') {
      changedQuantity = quantity > 1 ? quantity - 1 : 1;
    } else if (value === 'add') {
      changedQuantity = quantity ? quantity + 1 : 1;
    } else {
      changedQuantity = parseInt(e.target.value);
    }

    await updateProductInCheckout(id, changedQuantity);
    setQuantity(changedQuantity);
  };

  return (
    <StyledCartItem>
      {image && (
        <StyledImage
          src={image.src}
          alt={image.altText || fullTitle}
          loading="lazy"
        />
      )}
      <StyledContent>
        <StyledHeading>
          <Link to={buildUrl(handle)}>{fullTitle}</Link>
        </StyledHeading>
        <StyledPrice variant={variant} regularColour={brandColours.primary} />
        <StyledQuantity
          quantity={quantity}
          changeFunction={changeQuantity}
          isCartItem={true}
        />
        <StyledRemove onClick={() => removeProductFromCheckout(id)}>
          Remove
        </StyledRemove>
      </StyledContent>
    </StyledCartItem>
  );
};

export default CartItem;
