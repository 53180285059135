import React from 'react';
import styled from 'styled-components';
import { brandFonts, visuallyHidden } from '../styles';
import { isOnSale, formatPrice } from '../utils';

const StyledPrice = styled.p`
  font-family: ${brandFonts.secondary};
`;

const StyledOldPrice = styled.s`
  margin-right: 10px;
`;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

export const ProductPrice = ({
  priceRange: { minVariantPrice, maxVariantPrice },
  variants,
  ...props
}) => {
  const hasSalePrice = isOnSale(variants);

  let lowestCompareAtPrice;

  if (hasSalePrice) {
    const variantsOnSale = variants.filter(
      variant =>
        variant.compareAtPrice !== null &&
        parseFloat(variant.price) < parseFloat(variant.compareAtPrice)
    );

    if (variantsOnSale.length > 1) {
      variantsOnSale.sort(
        (a, b) => parseFloat(a.compareAtPrice) - parseFloat(b.compareAtPrice)
      );
    }

    lowestCompareAtPrice = variantsOnSale[0].compareAtPrice;
  }

  return (
    <StyledPrice {...props}>
      {hasSalePrice && (
        <StyledOldPrice>{formatPrice(lowestCompareAtPrice)}</StyledOldPrice>
      )}
      <StyledLabel>{hasSalePrice ? 'Sale price' : 'Regular price'}</StyledLabel>
      {minVariantPrice.amount !== maxVariantPrice.amount ? 'From ' : ''}
      {formatPrice(minVariantPrice.amount, minVariantPrice.currencyCode)}
    </StyledPrice>
  );
};

export const VariantPrice = ({ variant, ...props }) => {
  const hasSalePrice = isOnSale([variant]);
  const { price, compareAtPrice } = variant;

  return (
    <StyledPrice {...props}>
      {hasSalePrice && (
        <StyledOldPrice>{formatPrice(compareAtPrice)}</StyledOldPrice>
      )}
      <StyledLabel>{hasSalePrice ? 'Sale price' : 'Regular price'}</StyledLabel>
      {formatPrice(price > 0 ? price : price.amount)}
    </StyledPrice>
  );
};
