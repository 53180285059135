import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  headingStyles,
  visuallyHidden,
} from '../../styles';
import { Wave } from './Wave';

const StyledHeading = styled.h2`
  ${({ colour }) => {
    if (colour) {
      return css`
        color: ${colour};
      `;
    }
  }}

  ${({ hide }) => {
    if (hide) {
      return css`
        ${visuallyHidden()};
      `;
    } else {
      return css`
        ${headingStyles()};
      `;
    }
  }}

  span {
    display: block;
  }
`;

const StyledWave = styled(Wave)`
  display: inline-block;
  margin: 20px 0 15px;

  ${({ colour }) => {
    if (colour) {
      return css`
        stroke: ${colour};
      `;
    }
  }}

  ${minBreakpointQuery.tiny`
    margin-top: 25px;
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 30px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 35px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 35px;
  `}
`;

export const Heading = ({ colour, removeWave, hide, children, ...props }) => (
  <StyledHeading colour={colour} hide={hide} {...props}>
    <span>{children}</span>
    {!removeWave && !hide && <StyledWave colour={colour} />}
  </StyledHeading>
);
