import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { Container } from './ui';

const StyledFeaturedLogos = styled.section`
  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${sectionMargins('30px', '50px')};
      `;
    } else {
      return css`
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledInner = styled.div`
  ${maxBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  `}

  ${maxBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledText = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fontSize(20)};

  ${maxBreakpointQuery.medium`
    grid-column: span 4;
    text-align: center;
  `}

  ${maxBreakpointQuery.small`
    grid-column: span 2;
  `};

  ${minBreakpointQuery.tiny`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledItem = styled.a`
  display: block;
  text-align: center;
`;

const StyledImage = styled.img`
  max-height: 30px;
  max-width: 180px;

  ${minBreakpointQuery.mlarge`
    max-height: 40px;
    max-width: 240px;
  `}

  ${minBreakpointQuery.large`
    height: 50px;
    max-width: 300px;
  `}
`;

const FeaturedLogos = ({ isHomepage }) => {
  const {
    datoCmsFeaturedLogosSitewide: { logos },
  } = useStaticQuery(graphql`
    query FeaturedLogosQuery {
      datoCmsFeaturedLogosSitewide {
        logos {
          id
          logo {
            url
            alt
          }
          url
        }
      }
    }
  `);

  return (
    <StyledFeaturedLogos isHomepage={isHomepage}>
      <Container>
        <StyledInner>
          <StyledText>Featured in:</StyledText>
          {logos.map(({ id, logo, url }) => (
            <StyledItem
              key={id}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledImage src={logo.url} alt={logo.alt} loading="lazy" />
            </StyledItem>
          ))}
        </StyledInner>
      </Container>
    </StyledFeaturedLogos>
  );
};

export default FeaturedLogos;
