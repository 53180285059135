import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  zIndexLayers,
  visuallyHidden,
} from '../styles';

const StyledPopOut = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  background-color: ${standardColours.lighterGrey};
  z-index: ${zIndexLayers.fourth};
`;

const StyledHeader = styled.header`
  padding-bottom: 30px;
  border-bottom: 1px solid ${brandColours.primary};
`;

const StyledHeading = styled.p`
  font-family: ${brandFonts.secondary};
  ${fontSize(20)};
  line-height: 1.4;

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(24)};
  `}
`;

const StyledClose = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  background: none;
  border: none;

  &:before {
    content: '✕';
    color: ${standardColours.darkGrey};
    font-weight: ${fontWeights.bold};
    ${fontSize(22)};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PopOut = ({ heading, toggleOpen, children, ...props }) => {
  return (
    <StyledPopOut {...props}>
      <StyledHeader>
        <StyledHeading>{heading}</StyledHeading>
        <StyledClose onClick={toggleOpen}>
          <span>Close</span>
        </StyledClose>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledPopOut>
  );
};

export default PopOut;
