import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fluidFontSize,
  zIndexLayers,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Link } from './ui';

const StyledNavigation = styled.nav`
  ${minBreakpointQuery.mlarge`
    margin-left: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 60px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-left: 80px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 100px;
  `}
`;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 0;
    padding: 8px 0;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 2px;
      width: 25px;
      margin: 5px 20px;
      background-color: ${standardColours.darkGrey};
      ${standardTransition('transform')};
    `}

    ${maxBreakpointQuery.tsmall`
      width: 20px;
      margin-top: 4px;
      margin-bottom: 4px;
    `}

    ${({ displayNav }) => {
      if (displayNav) {
        return css`
          ${maxBreakpointQuery.mlarge`
            opacity: 0;
          `}

          &:first-child {
            ${maxBreakpointQuery.mlarge`
              opacity: 1;
              transform: translateY(7px) rotate(45deg);
            `}

            ${maxBreakpointQuery.tsmall`
              transform: translateY(6px) rotate(45deg);
            `}
          }

          &:last-child {
            ${maxBreakpointQuery.mlarge`
              opacity: 1;
              transform: translateY(-7px) rotate(-45deg);
            `}

            ${maxBreakpointQuery.tsmall`
              transform: translateY(-6px) rotate(-45deg);
            `}
          }
        `;
      }
    }}
  }
`;

const StyledList = styled.ul`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    right: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    margin-top: 28px;
    padding: 30px;
    background-color: ${brandColours.primary}
  `}

  ${maxBreakpointQuery.tsmall`
    margin-top: 14px;
    padding-top: 25px;
    padding-bottom: 25px;
  `}
`;

// moved up for hover
const StyledLink = styled(Link)`
  font-weight: ${fontWeights.medium};
  ${standardTransition('color')};

  ${maxBreakpointQuery.mlarge`
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${standardColours.white};
    ${fluidFontSize(
      '18px',
      '24px',
      breakpointSizes.tiny,
      breakpointSizes.mlarge
    )};
  `}

  ${minBreakpointQuery.mlarge`
    padding: 30px 5px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 35px;
    padding-bottom: 35px;
  `}

  ${({ isShop }) => {
    if (isShop) {
      return css`
        span {
          position: relative;
          padding-right: 16px;

          &:after {
            ${minBreakpointQuery.mlarge`
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              height: 8px;
              width: 8px;
              border: 2px solid ${standardColours.grey};
              border-top: none;
              border-left: none;
              transform: translateY(-4px) rotate(45deg);
              ${standardTransition('border-color')};
            `}
          }
        }
      `;
    }
  }}
`;

// moved up for hover
const StyledMegaMenu = styled.div`
  ${minBreakpointQuery.mlarge`
    position: absolute;
    z-index: ${zIndexLayers.fourth};
    padding-top: 30px;
    padding-bottom: 60px;
    left: -9999em;
    width: 100%;
    background-color: ${standardColours.lighterGrey};
  `}

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-top: 50px;
    padding-bottom: 100px;
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 8px;
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    margin-left: 10px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 20px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-left: 30px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }

  &:hover,
  &.current-page {
    ${StyledLink} {
      color: ${brandColours.quinary};

      ${minBreakpointQuery.mlarge`
        color: ${brandColours.primary};
      `}

      ${({ isShop }) => {
        if (isShop) {
          return css`
            span {
              &:after {
                ${minBreakpointQuery.mlarge`
                  border-color: ${brandColours.primary};
                `}
              }
            }
          `;
        }
      }}
    }

    ${StyledMegaMenu} {
      left: 0;
    }
  }
`;

const StyledMegaMenuContainer = styled(Container)`
  ${maxBreakpointQuery.mlarge`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledMegaMenuInner = styled.div`
  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: 3fr repeat(2, 1fr);
    align-items: start;
    gap: 30px;
  `}
`;

const StyledMegaMenuList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  `}
`;

const StyledMegaMenuItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 4px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}
  }
`;

const StyledMegaMenuLink = styled(Link)`
  padding-top: 4px;
  padding-bottom: 4px;
  ${standardTransition('color')};

  ${maxBreakpointQuery.mlarge`
    display: block;
    color: ${standardColours.white};
    ${fluidFontSize(
      '16px',
      '22px',
      breakpointSizes.tiny,
      breakpointSizes.mlarge
    )};
  `}

  ${minBreakpointQuery.mlarge`
    font-weight: ${fontWeights.medium};
  `}

  &:hover,
  &.current-page {
    color: ${brandColours.quinary};

    ${minBreakpointQuery.mlarge`
      color: ${brandColours.primary};
    `}
  }
`;

// moved up for hover
const StyledCtaImage = styled(GatsbyImage)`
  ${minBreakpointQuery.mlarge`
    ${standardTransition('transform')};
  `}
`;

const StyledCta = styled(Link)`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    position: relative;
    overflow: hidden;
  `}

  &:hover {
    ${StyledCtaImage} {
      ${minBreakpointQuery.mlarge`
        transform: scale(1.05);
      `}
    }
  }
`;

const StyledCtaText = styled.span`
  ${minBreakpointQuery.mlarge`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 10px;
    width: 75%;
    font-family: ${brandFonts.secondary};
    font-weight: ${fontWeights.bold};
    background-color: ${standardColours.lighterGrey};
  `}
`;

const Navigation = ({ ...props }) => {
  const {
    datoCmsHeader: { navigationShopLink, navigation, navigationCtas },
    allShopifyCollection: { categories },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      datoCmsHeader {
        navigationShopLink {
          text
          page {
            ...LinkFragment
          }
        }
        navigation {
          id
          text
          page {
            ...LinkFragment
          }
        }
        navigationCtas {
          image {
            gatsbyImageData(width: 232, height: 232)
            alt
          }
          link {
            text
            page {
              ...LinkFragment
            }
          }
        }
      }
      allShopifyCollection(sort: { fields: updatedAt, order: DESC }) {
        categories: nodes {
          title
          handle
        }
      }
    }
  `);

  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledNavigation {...props}>
      <StyledToggle
        aria-label="Open / Close Navigation"
        displayNav={displayNav}
        onClick={() => setDisplayNav(!displayNav)}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledList displayNav={displayNav}>
        <StyledItem isShop={true}>
          <StyledLink
            to={navigationShopLink.page.slug}
            linkData={navigationShopLink.page}
            isShop={true}
            activeClassName="current-page"
          >
            <span>{navigationShopLink.text}</span>
          </StyledLink>
          <StyledMegaMenu>
            <StyledMegaMenuContainer>
              <StyledMegaMenuInner>
                <StyledMegaMenuList>
                  {categories.map(({ title, handle }, id) => (
                    <StyledMegaMenuItem key={id}>
                      <StyledMegaMenuLink
                        to={`shop/${handle}`}
                        activeClassName="current-page"
                      >
                        {title}
                      </StyledMegaMenuLink>
                    </StyledMegaMenuItem>
                  ))}
                </StyledMegaMenuList>
                {navigationCtas.map(({ link, image }, id) => (
                  <StyledCta key={id} to={link.page.slug} linkData={link.page}>
                    <StyledCtaImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                    />
                    <StyledCtaText>{link.text}</StyledCtaText>
                  </StyledCta>
                ))}
              </StyledMegaMenuInner>
            </StyledMegaMenuContainer>
          </StyledMegaMenu>
        </StyledItem>
        {navigation.map(({ id, text, page }) => (
          <StyledItem key={id}>
            <StyledLink
              to={page.slug}
              linkData={page}
              activeClassName="current-page"
            >
              {text}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
    </StyledNavigation>
  );
};

export default Navigation;
