export const buildUrl = (slug, linkData) => {
  let url = '/';

  if (linkData) {
    if (linkData.treeParent) {
      if (
        linkData.treeParent.treeParent &&
        linkData.treeParent.treeParent.treeParent
      ) {
        url += `${linkData.treeParent.treeParent.treeParent.slug}/`;
      }

      if (linkData.treeParent.treeParent) {
        url += `${linkData.treeParent.treeParent.slug}/`;
      }

      url += `${linkData.treeParent.slug}/`;
    } else if (linkData.internal && linkData.internal.type) {
      if (linkData.internal.type === 'DatoCmsProduct') {
        url += 'shop/';
      } else if (linkData.internal.type === 'DatoCmsProduct') {
        url += 'products/';
      } else if (linkData.internal.type === 'DatoCmsPlantsForBusinessPage') {
        url += 'plants-for-business/';
      } else if (linkData.internal.type === 'DatoCmsProject') {
        url += 'plants-for-business/';
      } else if (linkData.internal.type === 'DatoCmsService') {
        url += 'plants-for-business/';
      } else if (linkData.internal.type === 'DatoCmsSector') {
        url += 'plants-for-business/';
      } else if (linkData.internal.type === 'DatoCmsBlogCategory') {
        url += 'blog/';
      } else if (
        linkData.internal.type === 'DatoCmsBlog' ||
        linkData.internal.type === 'DatoCmsBlogAuthor'
      ) {
        url += 'blog/';
      }
    }
  }

  url += `${slug.replace(/^\/|\/$/g, '')}/`;

  return url;
};
