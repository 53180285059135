import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import SitewideNotice from './SitewideNotice';
import Logo from './Logo';
import Navigation from './Navigation';
import Search from './Search';
import { getCartQuantity } from '../utils';
import { StoreContext } from '../context/StoreContext';
import searchIcon from '../images/search-icon.svg';
import personIcon from '../images/person-icon.svg';
import bagIcon from '../images/bag-icon.svg';

const StyledHeader = styled.header`
  position: relative;
  border-bottom: 1px solid ${standardColours.lightGrey};
`;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.mlarge`
    padding-right: 10px;
    padding-left: 10px;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  ${minBreakpointQuery.tsmall`
    padding-top: 15px;
    padding-bottom: 15px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const StyledNavigation = styled(Navigation)`
  ${maxBreakpointQuery.mlarge`
    flex-basis: calc(100% / 3);
  `}

  ${minBreakpointQuery.mlarge`
    order: 2;
  `}
`;

const StyledLogo = styled(Logo)`
  fill: ${brandColours.primary};

  ${maxBreakpointQuery.mlarge`
    flex-basis: calc(100% / 3);
    text-align: center;
  `}

  ${minBreakpointQuery.mlarge`
    order: 1;
  `}
`;

const StyledButtons = styled.div`
  ${maxBreakpointQuery.mlarge`
    flex-basis: calc(100% / 3);
    text-align: right;
  `}

  ${minBreakpointQuery.mlarge`
    order: 3;
    margin-left: auto;
  `}
`;

const commonButtonLinkStyles = () => {
  return css`
    margin-right: 10px;
    padding: 10px;
  `;
};

const StyledButton = styled.button`
  ${commonButtonLinkStyles()};
  background: none;
  border: none;
`;

const StyledLink = styled.a`
  ${commonButtonLinkStyles()};

  ${maxBreakpointQuery.small`
    ${visuallyHidden()};
  `}
`;

const StyledSvg = styled(Svg)`
  height: 18px;
  width: 18px;
  fill: ${standardColours.darkGrey};

  ${minBreakpointQuery.tsmall`
    height: 20px;
    width: 20px;
  `}
`;

const StyledQuantity = styled.span`
  position: relative;
  bottom: 2px;
  font-weight: ${fontWeights.bold};
`;

const Header = () => {
  const { toggleSearchOpen, toggleCartOpen, checkout } = useContext(
    StoreContext
  );

  return (
    <StyledHeader>
      <SitewideNotice />
      <StyledContainer>
        <StyledInner>
          <StyledNavigation />
          <StyledLogo />
          <StyledButtons>
            <StyledButton onClick={toggleSearchOpen}>
              <StyledSvg title="Search" image={searchIcon} />
            </StyledButton>
            <StyledLink
              href={`https://${process.env.GATSBY_CUSTOM_SHOPIFY_STORE_URL}/account/`}
            >
              <StyledSvg title="Account" image={personIcon} />
            </StyledLink>
            <StyledButton onClick={toggleCartOpen}>
              <StyledSvg title="Shopping Bag" image={bagIcon} />
              <StyledQuantity>{getCartQuantity(checkout)}</StyledQuantity>
            </StyledButton>
          </StyledButtons>
          <Search />
        </StyledInner>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
