import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  headingStyles,
  standardTransition,
} from '../../styles';

export const StyledHtmlContent = styled.div`
  ${({ center }) => {
    if (center) {
      return css`
        text-align: center;
      `;
    }
  }}

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 12px 0;
    font-weight: ${fontWeights.bold};
    line-height: 1.5;

    ${minBreakpointQuery.small`
      margin-top: 14px;
      margin-bottom: 14px;
    `}
  }

  h2 {
    ${headingStyles()};
  }

  h3 {
    ${fontSize(24)};

    ${minBreakpointQuery.small`
      ${fontSize(26)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(28)};
    `}
  }

  h4 {
    ${fontSize(22)};

    ${minBreakpointQuery.small`
      ${fontSize(24)};
    `}
  }

  h5 {
    ${fontSize(20)};

    ${minBreakpointQuery.small`
      ${fontSize(22)};
    `}
  }

  h6 {
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}
  }

  p {
    margin-bottom: 20px;
    line-height: 1.7;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(17)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(18)};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    color: ${brandColours.primary};
    border-bottom: 1px solid transparent;
    ${standardTransition('border-color')};

    &:hover {
      border-bottom-color: ${brandColours.primary};
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    li {
      margin-bottom: 10px;
      line-height: 1.7;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
        ${fontSize(17)};
    `}

      ${minBreakpointQuery.large`
        ${fontSize(18)};
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: ${brandColours.primary};
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }
`;

export const HtmlContent = ({ html, center, ...props }) => (
  <StyledHtmlContent
    dangerouslySetInnerHTML={{ __html: html }}
    center={center}
    {...props}
  />
);
