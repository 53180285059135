import React, { useContext } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import {
  breakpointSizes,
  standardColours,
  brandColours,
  fluidFontSize,
  visuallyHidden,
} from '../styles';
import { StoreContext } from '../context/StoreContext';

const StyledSearchBox = styled.form`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  ${fluidFontSize('18px', '28px', breakpointSizes.tiny, breakpointSizes.large)};
  border: none;
  border-bottom: 1px solid ${brandColours.primary};
`;

const StyledButton = styled.button`
  padding: 0 10px;
  border: none;
  background: none;

  &:before {
    content: '×';
    color: ${standardColours.darkGrey};
    ${fluidFontSize(
      '40px',
      '60px',
      breakpointSizes.tiny,
      breakpointSizes.large
    )};
  }

  span {
    ${visuallyHidden()};
  }
`;

const SearchBox = ({ refine, currentRefinement, onFocus }) => {
  const { toggleSearchOpen } = useContext(StoreContext);

  return (
    <StyledSearchBox>
      <StyledInput
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={e => e.target.value.length > 2 && refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <StyledButton onClick={toggleSearchOpen}>
        <span>Close</span>
      </StyledButton>
    </StyledSearchBox>
  );
};

export default connectSearchBox(SearchBox);
