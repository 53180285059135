import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
  fontSize,
} from '../styles';
import { Heading, Button } from './ui';
import { dataLayer } from '../utils';

const StyledNewsletterSignup = styled.section``;

const StyledHeading = styled(Heading)`
  color: ${standardColours.white};
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}

  ${({ $isPopup }) => {
    if ($isPopup) {
      return css`
        ${fontSize(28)};

        ${minBreakpointQuery.large`
          ${fontSize(36)};
        `}
      `;
    } else {
      return css`
        margin-bottom: 20px;
      `;
    }
  }};
`;

const StyledText = styled.p`
  margin-bottom: 16px;
  color: ${standardColours.white};
  ${fontSize(15)};

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
    ${fontSize(16)};
  `}

  ${({ $isPopup }) => {
    if ($isPopup) {
      return css`
        margin-bottom: 30px;

        ${minBreakpointQuery.large`
          margin-bottom: 40px;
        `}
      `;
    }
  }};
`;

const StyledForm = styled.form``;

const StyledLabel = styled.label`
  display: block;
  margin: 10px 0;

  span {
    ${visuallyHidden()};
  }
`;

const StyledInput = styled.input`
  padding: 20px;
  height: 64px;
  width: 100%;
  color: ${standardColours.white};
  background: none;
  border: 1px solid ${standardColours.white};

  &::placeholder {
    color: ${standardColours.white};
  }
`;

const StyledButton = styled(Button)`
  justify-content: left;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
`;

const NewsletterSignup = ({ heading, text, isPopup, closePopup, ...props }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const emailInput = useRef();

  const handleSubmit = event => {
    event.preventDefault();
    fetch('/api/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        email: emailInput.current.value,
      }),
    })
      .then(() => {
        setFormSubmitted(true);
        dataLayer('newsletterFormSubmit');
        if (isPopup && closePopup) {
          closePopup();
        }
      })
      .catch(error => alert(error));
  };

  return (
    <StyledNewsletterSignup $isPopup={isPopup} {...props}>
      <StyledHeading $isPopup={isPopup} removeWave={!isPopup}>
        {heading ? heading : 'Sign up to our newsletter'}
      </StyledHeading>
      {text && <StyledText $isPopup={isPopup}>{text}</StyledText>}
      <StyledForm method="post" onSubmit={handleSubmit}>
        <StyledLabel for="newsletter-email">
          <span>Your email address</span>
          <StyledInput
            type="email"
            name="newsletter-email"
            ref={emailInput}
            placeholder="Your email address"
            required
          />
        </StyledLabel>
        <StyledButton removeArrow={true} disabled={formSubmitted}>
          {formSubmitted ? 'Thanks' : 'Subscribe'}
        </StyledButton>
      </StyledForm>
    </StyledNewsletterSignup>
  );
};

export default NewsletterSignup;
