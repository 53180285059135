import React from 'react';
import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  standardTransition,
} from '../../styles';
import { Link } from './Link';
import { Svg } from './Svg';
import arrowIcon from '../../images/arrow-icon.svg';

// at the top for hover
const StyledIcon = styled(Svg)`
  display: inline-block;
  margin-right: 20px;

  ${({ alt }) => {
    if (alt) {
      return css`
        padding: 10px;
        height: 38px;
        width: 38px;
        fill: ${standardColours.white};
        border: 1px solid ${standardColours.white};
        border-radius: 50%;
        ${standardTransition('border-color')};
      `;
    } else {
      return css`
        height: 13px;
        width: 18px;
        fill: ${brandColours.primary};
        ${standardTransition('fill')};
      `;
    }
  }}
`;

const commonButtonStyles = () => {
  return css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: ${fontWeights.medium};
    line-height: 1.5;
    text-align: center;
    background: none;

    ${({ alt }) => {
      if (alt) {
        return css`
          color: ${standardColours.white};
          border: none;
          ${standardTransition('color')};

          &:hover {
            color: ${brandColours.quaternary};

            ${StyledIcon} {
              fill: ${brandColours.quaternary};
              border-color: ${brandColours.quaternary};
            }
          }
        `;
      } else {
        return css`
          padding: 18px 30px;
          color: ${brandColours.primary};
          border: 2px dashed ${brandColours.primary};
          ${standardTransition('background-color')};

          &:hover {
            color: ${standardColours.white};
            background-color: ${brandColours.primary};

            ${StyledIcon} {
              fill: ${standardColours.white};
            }
          }
        `;
      }
    }}
  `;
};

const StyledButton = styled.button`
  ${({ alt }) => commonButtonStyles(alt)}
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${({ alt }) => commonButtonStyles(alt)}
`;

export const Button = ({ alt, removeArrow, children, ...props }) => {
  const ButtonComponent = props.to ? StyledButtonLink : StyledButton;

  return (
    <ButtonComponent alt={alt} {...props}>
      {!removeArrow && <StyledIcon image={arrowIcon} alt={alt} />}
      {children || 'Learn more'}
    </ButtonComponent>
  );
};
