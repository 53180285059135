import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { buildUrl } from '../../utils';

const StyledLink = styled(GatsbyLink)`
  display: inline-block;
`;

export const Link = ({ to, linkData, children, ...props }) => (
  <StyledLink to={to === '/' ? to : buildUrl(to, linkData)} {...props}>
    {children}
  </StyledLink>
);

export const LinkFragment = graphql`
  fragment LinkFragment on Node {
    ... on DatoCmsHome {
      slug
    }

    ... on DatoCmsPage {
      slug
    }

    ... on DatoCmsShop {
      slug
    }

    ... on DatoCmsProduct {
      slug: shopifyProduct
      internal {
        type
      }
    }

    ... on DatoCmsPlantsForBusiness {
      slug
    }

    ... on DatoCmsPlantsForBusinessPage {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsProject {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsService {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsSector {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsBlogArchive {
      slug
    }

    ... on DatoCmsBlogCategory {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsBlog {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsBlogAuthor {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsContact {
      slug
    }
  }
`;
