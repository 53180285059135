import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
} from '../styles';
import PopOut from './PopOut';
import CartItem from './CartItem';
import { StoreContext } from '../context/StoreContext';
import { getCartQuantity, formatPrice } from '../utils';

const StyledCart = styled(PopOut)`
  right: 0;
`;

const StyledList = styled.ul``;

const StyledItem = styled.li`
  border-top: 1px solid ${brandColours.primary};

  &:first-child {
    border-top: 0;
  }
`;

const StyledSubtotal = styled.p`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  border-top: 1px solid ${brandColours.primary};
`;

const StyledText = styled.p`
  display: grid;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  border-top: 1px solid ${brandColours.primary};

  span {
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.light};
    ${fontSize(15)};
  }
`;

const StyledButton = styled.button`
  padding: 20px;
  width: 100%;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  line-height: 1.5;
  background-color: ${brandColours.primary};
  border: none;
`;

const Cart = () => {
  const { checkout, isCartOpen, toggleCartOpen } = useContext(StoreContext);
  const { lineItems, totalPrice, webUrl } = checkout;
  const [checkoutUrl, setCheckoutUrl] = useState();
  const minAmount = 25;

  const handleCheckoutClick = async () => {
    window.location.href = checkoutUrl;
  };

  useEffect(
    () =>
      setCheckoutUrl(
        webUrl.replace(
          `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
          process.env.GATSBY_CUSTOM_SHOPIFY_STORE_URL
        )
      ),
    [webUrl]
  );

  return (
    isCartOpen && (
      <StyledCart
        heading={`Your Bag (${getCartQuantity(checkout)})`}
        toggleOpen={toggleCartOpen}
      >
        {lineItems.length > 0 ? (
          <>
            <StyledList>
              {lineItems.map(
                item =>
                  item.variant && (
                    <StyledItem>
                      <CartItem key={item.id} data={item} />
                    </StyledItem>
                  )
              )}
            </StyledList>
            <StyledSubtotal>
              <span>Subtotal:</span> {formatPrice(totalPrice.amount)}
            </StyledSubtotal>
            {totalPrice.amount < minAmount ? (
              <>
                <StyledText>
                  Sorry! Basket must reach {formatPrice(minAmount)} to checkout.
                  <span>
                    This allows us to optimise our new direct shipping model and
                    reduce unnecessary packaging, contributing to a greener
                    plant-buying experience.
                  </span>
                </StyledText>
                <StyledButton onClick={toggleCartOpen}>
                  Continue Shopping (
                  {formatPrice(minAmount - totalPrice.amount)} more needed)
                </StyledButton>
              </>
            ) : (
              <StyledButton onClick={handleCheckoutClick}>
                Go to Checkout
              </StyledButton>
            )}
          </>
        ) : (
          <>
            <StyledText>Your Cart is Empty</StyledText>
            <StyledButton onClick={toggleCartOpen}>
              Continue Shopping
            </StyledButton>
          </>
        )}
      </StyledCart>
    )
  );
};

export default Cart;
